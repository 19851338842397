import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from '@tanstack/react-query';

import { selectedAudit } from '../state/audit';
import {
    ApiReview,
    ApiReviewMessage,
    Review,
    ReviewMessage,
    ReviewStatus,
    ReviewType,
} from '../Types/Review';
import {
    transformReview,
    transformReviewMessage,
    transformReviews,
    transformReviewStatusToApiStatus,
} from './reviewTransformer';

// Fetch function
async function fetchFromAPI<T>(endpoint: string, options?: RequestInit): Promise<T> {
    const response = await fetch(endpoint, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
}

export interface CreateReviewPayload {
    reviewableId: number;
    status: ReviewStatus | string;
    type: ReviewType;
    message?: string;
}

// Create review function
async function createReview(reviewPayload: CreateReviewPayload): Promise<ApiReview> {
    const review = {
        status: transformReviewStatusToApiStatus(reviewPayload.status as ReviewStatus),
        type: reviewPayload.type,
        message: reviewPayload.message,
    };

    return fetchFromAPI<ApiReview>(`/api/cards/${reviewPayload.reviewableId}/reviews`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...review }),
    });
}

export interface UpdateReviewPayload {
    reviewableId: number;
    reviewId: number;
    status: string;
    type: ReviewType;
}

async function updateReview(payload: UpdateReviewPayload): Promise<ApiReview> {
    const mutatedPayload = {
        status: transformReviewStatusToApiStatus(payload.status as ReviewStatus),
        type: payload.type,
    };
    return fetchFromAPI<ApiReview>(
        `/api/cards/${payload.reviewableId}/reviews/${payload.reviewId}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(mutatedPayload),
        },
    );
}

interface CreateMessagePayload {
    reviewId: number;
    message: string;
}

async function createMessage(payload: CreateMessagePayload): Promise<ApiReviewMessage> {
    return fetchFromAPI<ApiReviewMessage>(`/api/reviews/${payload.reviewId}/messages`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: payload.message }),
    });
}

interface UpdateMessagePayload {
    id: number;
    message: string;
}

async function updateMessage(payload: UpdateMessagePayload): Promise<ApiReviewMessage> {
    return fetchFromAPI<ApiReviewMessage>(`/api/messages/${payload.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: payload.message }),
    });
}

async function destroyMessage(payload: { id: number }): Promise<ApiReviewMessage> {
    return fetchFromAPI<ApiReviewMessage>(`/api/messages/${payload.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: payload.id }),
    });
}

// React Query hooks
export const useGetAuditReviews = (auditId?: number): UseQueryResult<Review[], Error> => {
    return useQuery<ApiReview[], Error, Review[]>({
        queryKey: ['audits', 'reviews', { auditId }],
        queryFn: () => fetchFromAPI<ApiReview[]>(`/api/audits/${auditId}/reviews`),
        enabled: !!auditId,
        select: (data) => transformReviews(data),
    });
};

export const useGetReview = (
    auditId?: number,
    reviewId?: number,
): UseQueryResult<Review, Error> => {
    const queryClient = useQueryClient();

    return useQuery<ApiReview, Error, Review>({
        queryKey: ['audits', 'reviews', 'get', { auditId, reviewId }],
        enabled: !!auditId && !!reviewId,
        queryFn: async () => {
            const cachedApiReviews = queryClient.getQueryData<ApiReview[]>([
                'audits',
                'reviews',
                { auditId },
            ]);

            if (cachedApiReviews) {
                const review = cachedApiReviews.find(
                    (review: ApiReview) => review.id === reviewId,
                );
                if (review) {
                    return review;
                }
            }

            const fetchedApiReviews = await fetchFromAPI<ApiReview[]>(
                `/api/audits/${auditId}/reviews`,
            );
            const review = fetchedApiReviews.find(
                (review: ApiReview) => review.id === reviewId,
            );

            if (!review) throw new Error('No review found');
            return review;
        },
        select: (data) => {
            return transformReview(data);
        },
    });
};

export const useGetCardsReviews = (cardId?: number): UseQueryResult<Review[], Error> => {
    return useQuery<ApiReview[], Error, Review[]>({
        queryKey: ['cards', 'reviews', { cardId }],
        queryFn: () => fetchFromAPI<ApiReview[]>(`/api/cards/${cardId}/reviews`),
        enabled: !!cardId,
        select: (data) => transformReviews(data),
    });
};

const flushSingleReviewFromCache = (
    queryClient: QueryClient,
    auditId: number,
    review: ApiReview,
) => {
    queryClient.setQueryData(
        ['audits', 'reviews', { auditId }],
        (previousReviews: ApiReview[] | undefined) => {
            if (previousReviews) {
                return previousReviews.map((review) =>
                    review.id === review.id ? review : review,
                );
            }
            return [];
        },
    );

    queryClient.setQueryData(
        ['audits', 'reviews', 'get', { auditId, reviewId: review.id }],
        () => {
            return review;
        },
    );

    queryClient.invalidateQueries({
        queryKey: ['audits', 'reviews', { auditId }],
        refetchType: 'none',
    });

    queryClient.invalidateQueries({
        queryKey: ['audits', 'reviews', 'get', { auditId, reviewId: review.id }],
        refetchType: 'none',
    });
};

export const useCreateReview = () => {
    const queryClient = useQueryClient();

    return useMutation<ApiReview, Error, CreateReviewPayload>({
        mutationFn: createReview,
        onSuccess: (review) => {
            const auditId = selectedAudit?.value?.id as number;
            flushSingleReviewFromCache(queryClient, auditId, review);
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useUpdateReview = () => {
    const queryClient = useQueryClient();

    return useMutation<ApiReview, Error, UpdateReviewPayload>({
        mutationFn: updateReview,
        onSuccess: (review) => {
            const auditId = selectedAudit?.value?.id as number;
            flushSingleReviewFromCache(queryClient, auditId, review);
        },
    });
};

export const useGetMessages = (
    reviewId?: number,
): UseQueryResult<ReviewMessage[], Error> => {
    return useQuery<ApiReviewMessage[], Error, ReviewMessage[]>({
        queryKey: ['review', 'messages', 'list', { reviewId }],
        queryFn: () =>
            fetchFromAPI<ApiReviewMessage[]>(`/api/reviews/${reviewId}/messages`),
        enabled: !!reviewId,
        select: (data) => data.map((message) => transformReviewMessage(message)),
    });
};

export const useUpdateMessage = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateMessage,
        onSuccess: (message) => {
            const auditId = selectedAudit?.value?.id as number;
            const reviewId = message.review_id;

            queryClient.invalidateQueries({
                queryKey: ['review', 'messages', 'list', { reviewId }],
                refetchType: 'none',
            });

            queryClient.invalidateQueries({
                queryKey: ['audits', 'reviews', 'get', { auditId, reviewId }],
                refetchType: 'none',
            });

            queryClient.setQueryData(
                ['review', 'messages', 'list', { reviewId }],
                (previousMessages: ApiReviewMessage[] | undefined) => {
                    if (previousMessages) {
                        previousMessages = [
                            message,
                            ...previousMessages.filter((e) => e.id !== message.id),
                        ];
                        previousMessages.sort(
                            (a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp),
                        );
                        return previousMessages;
                    }
                    return [];
                },
            );

            queryClient.setQueryData(
                ['audits', 'reviews', 'get', { auditId, reviewId }],
                (previousReview: ApiReview | undefined) => {
                    if (previousReview) {
                        previousReview.messages.push(message);
                        return previousReview;
                    }
                    return [];
                },
            );
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useDestroyMessage = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: destroyMessage,
        onSuccess: (message) => {
            const auditId = selectedAudit?.value?.id as number;
            const reviewId = message.review_id;

            queryClient.invalidateQueries({
                queryKey: ['review', 'messages', 'list', { reviewId }],
                refetchType: 'none',
            });

            queryClient.invalidateQueries({
                queryKey: ['audits', 'reviews', 'get', { auditId, reviewId }],
                refetchType: 'none',
            });

            queryClient.setQueryData(
                ['review', 'messages', 'list', { reviewId }],
                (previousMessages: ApiReviewMessage[] | undefined) => {
                    if (previousMessages) {
                        return [...previousMessages.filter((e) => e.id !== message.id)];
                    }
                    return [];
                },
            );

            queryClient.setQueryData(
                ['audits', 'reviews', 'get', { auditId, reviewId }],
                (previousReview: ApiReview | undefined) => {
                    if (previousReview) {
                        previousReview.messages = previousReview.messages.filter(
                            (e) => e.id !== message.id,
                        );
                        return previousReview;
                    }
                    return [];
                },
            );
        },
        onError: (error) => {
            console.error(error);
        },
    });
};

export const useCreateMessage = (reviewId: number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createMessage,
        onSuccess: (message) => {
            const auditId = selectedAudit?.value?.id as number;

            queryClient.setQueryData(
                ['audits', 'reviews', { auditId }],
                (previousReviews: ApiReview[] | undefined) => {
                    if (previousReviews) {
                        return previousReviews.map((review) => {
                            if (review.id == reviewId) {
                                review.messages.push(message);
                            }
                            return review;
                        });
                    }
                    return [];
                },
            );

            queryClient.setQueryData(
                ['audits', 'reviews', 'get', { auditId, reviewId: reviewId }],
                (previousReview: ApiReview | undefined) => {
                    if (previousReview) {
                        previousReview.messages.push(message);
                        return previousReview;
                    }
                    return [];
                },
            );

            queryClient.setQueryData(
                ['review', 'messages', 'list', { reviewId }],
                (previousMessages: ApiReviewMessage[] | undefined) => {
                    if (previousMessages) {
                        return [...previousMessages, message];
                    }
                    return [];
                },
            );

            queryClient.invalidateQueries({
                queryKey: ['audits', 'reviews', 'get', { auditId, reviewId }],
                refetchType: 'none',
            });

            queryClient.invalidateQueries({
                queryKey: ['review', 'messages', 'list', { reviewId }],
                refetchType: 'none',
            });
            //flushSingleReviewFromCache(queryClient, auditId, review);
        },
        onError: (error) => {
            console.error(error);
        },
    });
};
