import { upperFirst } from 'lodash';

import { ApiAudit, ApiAuditBoard, TransformedAuditBoard } from '../Types/Audit';
import { ApiCard, Card, CardTestResultDetails, ResultDetails } from '../Types/Card';
import {
    transformStandardMappings,
    transformStandardMappingsToApi,
} from './standardMappingTransformer';

const boardStatusMap: Record<string, string> = {
    pending: 'Pending',
    in_progress: 'In Progress',
    locked: 'Locked',
};

export function transformBoardData(
    audits: Partial<ApiAudit>[],
): Partial<TransformedAuditBoard>[] {
    return audits.map((audit) => ({
        id: audit.audit_board?.id,
        title: audit.audit_board?.title,
        auditType: audit.audit_board?.audit_type,
        status: audit.audit_board?.status
            ? boardStatusMap[audit.audit_board.status]
            : undefined,
        auditCardCount: audit.audit_board?.audit_card_count,
        assignees: audit.audit_board?.assignees,
    }));
}

export function transformBoardDataToApi(
    transformedBoards: Partial<TransformedAuditBoard>[],
): Partial<ApiAuditBoard>[] {
    const reverseBoardStatusMap = Object.fromEntries(
        Object.entries(boardStatusMap).map(([k, v]) => [v, k]),
    );
    return transformedBoards.map((board) => ({
        id: board.id,
        title: board.title,
        audit_type: board.auditType,
        status: board.status ? reverseBoardStatusMap[board.status] : undefined,
        audit_card_count: board.auditCardCount,
        assignees: board.assignees,
    }));
}

export function transformCard(card: Partial<ApiCard>): Partial<Card> {
    return {
        id: card.id,
        refId: card.ref_id,
        worksheetStatus: card.worksheet_status
            ? worksheetStatusMap[card.worksheet_status]
            : worksheetStatusMap.incomplete,
        status: card.status ? cardStatusMap[card.status] : undefined,
        section: card.section,
        controlDescription: card.description ? card.description : undefined,
        auditProcedure: card.audit_procedure ? card.audit_procedure : undefined,
        results: {
            status: card.test_result
                ? cardResultsMap[card.test_result]
                : cardResultsMap.no_deviations_noted,
            details: card.result_details
                ? transformResultDetails(card.result_details)
                : undefined,
        },
        frequency: card.frequency,
        testingComments: card.testing_comments ? card.testing_comments : undefined,
        importance: card.importance ? upperFirst(card.importance) : undefined,
        standardMapping: card.standards
            ? transformStandardMappings(card.standards)
            : undefined,
        clientComments: 'some_comments_field', // Adjust based on your actual data structure
        attachments: card.attachments,
        uwvComments: card.uwv_comments,
        title: card.title,
        archived: card.archived,
    };
}

export function transformCardToApi(card: Partial<Card>): Partial<ApiCard> {
    const reverseCardStatusMap = Object.fromEntries(
        Object.entries(cardStatusMap).map(([k, v]) => [v, k]),
    );
    const reverseCardWorksheetStatusMap = Object.fromEntries(
        Object.entries(worksheetStatusMap).map(([k, v]) => [v, k]),
    );
    const reverseCardResultsMap = Object.fromEntries(
        Object.entries(cardResultsMap).map(([k, v]) => [v, k]),
    );
    return {
        id: card.id,
        ref_id: card.refId,
        status: card.status ? reverseCardStatusMap[card.status] : undefined,
        worksheet_status: card.worksheetStatus
            ? reverseCardWorksheetStatusMap[card.worksheetStatus]
            : undefined,
        section: card.section,
        description: card.controlDescription,
        audit_procedure: card.auditProcedure,
        test_result: card?.results?.status
            ? reverseCardResultsMap[card.results.status]
            : undefined,
        result_details: card.results?.details
            ? transformResultDetailsToApi(card.results.details)
            : undefined,
        testing_comments: card.testingComments,
        importance: card.importance ? card.importance.toLowerCase() : undefined,
        standards: card.standardMapping
            ? transformStandardMappingsToApi(card.standardMapping)
            : undefined,
        uwv_comments: card.uwvComments,
        archived: card.archived,
        frequency: card.frequency,
        title: card.title,
        manager_review: card.managerReview,
        supervisor_review: card.supervisorReview,
    };
}

export function transformCards(cards: Partial<ApiCard>[]): Partial<Card>[] {
    return cards.map(transformCard);
}

export function transformCardsToApi(cards: Partial<Card>[]): Partial<ApiCard>[] {
    return cards.map(transformCardToApi);
}

export const cardStatusMap: Record<string, string> = {
    not_ready: 'Control Gap',
    implemented: 'Implemented',
    awaiting_review: 'For Review',
    audit_query: 'Audit Query',
    accepted: 'Accepted',
};

export const worksheetStatusMap: Record<string, string> = {
    incomplete: 'Incomplete',
    //under_review: 'Under Review',
    //approved: 'Approved',
    //exceptions: 'Exceptions',
    in_progress: 'Testing In Progress',
    ready_for_review: 'Ready For Review',
    descoped: 'Descoped',
};

const cardResultsMap: Record<string, string> = {
    no_deviations_noted: 'No exceptions noted',
    exceptions_noted: 'Exceptions Noted',
    positive_observation: 'Positive Observation',
    process_improvement: 'Process Improvement',
    minor_non_conformity: 'Minor Non-conformity',
    major_non_conformity: 'Major Non-conformity',
};

function transformResultDetails(details: CardTestResultDetails): ResultDetails {
    return {
        //inquiry: details.inquiry as ResultSection,
        //exceptionDisclosure: details.exception_disclosure as ResultSection,
        //basisOfDesign: details.basis_of_design as ResultSection,
        tests: details.tests,
    };
}

function transformResultDetailsToApi(details: ResultDetails): CardTestResultDetails {
    return {
        //inquiry: details.inquiry as CardTestResultData,
        //exception_disclosure: details.exceptionDisclosure as CardTestResultData,
        //basis_of_design: details.basisOfDesign as CardTestResultData,
        tests: details.tests,
    };
}
