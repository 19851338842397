import Papa from 'papaparse';
import React, { useRef } from 'react';

import { useUpdateCardsByReview } from '../services/auditService';
import { TransformedAuditBoard } from '../Types/Audit';

interface ImportCSVProps {
    selectedAudit?: TransformedAuditBoard;
}

const ImportCSV: React.FC<ImportCSVProps> = ({ selectedAudit }) => {
    const updateCardsMutation = useUpdateCardsByReview();
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileSelect = () => {
        inputRef?.current?.click();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    results.data.map((row) => {
                        console.log('row', row);
                        const card = rowToCard(row);

                        updateCardsMutation.mutate({
                            auditId: selectedAudit?.id || 0,
                            card: card,
                        });
                    });
                },
            });
        }
    };

    const reviewStatusMap: Record<string, string> = {
        approved: 'Accepted',
        response_required: 'Response Required',
        not_started: 'Not Started',
    };

    const reverseReviewStatusMap = Object.fromEntries(
        Object.entries(reviewStatusMap).map(([k, v]) => [v, k]),
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowToCard: any = (row: any) => {
        const map = {
            section: 'Section',
            title: 'Title',
            refId: 'Ref ID',
            controlDescription: 'Control Description',
            auditProcedure: 'Service Auditor Tests',
            frequency: 'Frequency',
            importance: 'Importance',
            uwvComments: 'Comments',
            status: 'Board Status',
            worksheetStatus: 'Testing Status',
            testingComments: 'Test Results',
            results: 'Results',
            supervisorReview: 'Senior Review',
            managerReview: 'Manager Review',
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any = {};
        Object.entries(map).forEach(([k, v]) => {
            if (!row[v]) return;

            result[k] = row[v];
            if (v == 'Results') {
                const p = row[v].split('.');
                result[k] = {
                    status: p[0],
                    details: p[1],
                };
            }

            if (v == 'Senior Review' || v == 'Manager Review') {
                const p = row[v].split('.');
                result[k] = {
                    status: reverseReviewStatusMap[p[0]],
                    message: p[1],
                };
            }
        });
        return result;
    };

    return (
        <>
            <input
                type="file"
                id="importCSV"
                className="hidden"
                ref={inputRef}
                onChange={handleFileUpload}
            />
            <button
                onClick={handleFileSelect}
                className="ml-2 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Import CSV
            </button>
        </>
    );
};

export default ImportCSV;
