import {
    ApiReview,
    ApiReviewMessage,
    apiToReviewStatusMap,
    ApiUser,
    Review,
    ReviewMessage,
    ReviewStatus,
    reviewToApiStatusMap,
} from '../Types/Review';
import { PillarUser } from '../Types/User';

function transformUser(apiUser: ApiUser): PillarUser {
    return {
        id: apiUser.id,
        email: apiUser.email,
        firstName: apiUser.first_name || '',
        lastName: apiUser.last_name || '',
    };
}

export function transformReviewMessage(apiMessage: ApiReviewMessage): ReviewMessage {
    return {
        id: apiMessage.id,
        message: apiMessage.message || '',
        createdBy: transformUser(apiMessage.created_by),
        createdAt: apiMessage.timestamp,
        reviewId: apiMessage.review_id,
    };
}

export function transformReview(apiReview: ApiReview): Review {
    return {
        id: apiReview.id,
        reviewableType: apiReview.reviewable.control_type as string,
        reviewableId: apiReview.reviewable.id as number,
        createdBy: transformUser(apiReview.created_by),
        createdAt: apiReview.timestamp,
        status: transformApiStatusToReviewStatus(apiReview.status),
        messages: apiReview.messages.map(transformReviewMessage),
        reviewType: apiReview.review_type,
    };
}

export function transformReviews(apiReviews: ApiReview[]): Review[] {
    return apiReviews.map(transformReview);
}

export function transformReviewToApi(review: Review): ApiReview {
    return {
        id: review.id,
        created_by: {
            id: review.createdBy.id,
            email: review.createdBy.email,
            first_name: review.createdBy.firstName,
            last_name: review.createdBy.lastName,
        },
        timestamp: review.createdAt,
        status: transformReviewStatusToApiStatus(review.status),
        messages: review.messages?.map(transformMessageToApi),
        reviewable: {
            id: review.reviewableId,
            control_type: review.reviewableType,
        },
        review_type: review.reviewType,
    };
}

export function transformMessageToApi(message: ReviewMessage): ApiReviewMessage {
    return {
        id: message.id,
        message: message.message,
        created_by:
            {
                id: message.createdBy.id,
                email: message.createdBy.email,
                first_name: message.createdBy.firstName,
                last_name: message.createdBy.lastName,
            } || [],
        timestamp: message.createdAt,
        review_id: message.reviewId,
    };
}
function transformApiStatusToReviewStatus(apiStatus: string | null): ReviewStatus {
    return apiToReviewStatusMap[apiStatus || 'not_started'];
}

export function transformReviewStatusToApiStatus(reviewStatus: ReviewStatus): string {
    return reviewToApiStatusMap[reviewStatus];
}
