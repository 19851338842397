import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { Signal } from '@preact/signals-react';
import { Fragment, useState } from 'react';

import { useGetAudits } from '../services/auditService';
import { ApiAuditCardCount, TransformedAuditBoard } from '../Types/Audit';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

interface AuditSelectProps {
    selectedAudit?: Signal<TransformedAuditBoard | undefined>;
}

function calculateAcceptedPercentage(cardCount?: ApiAuditCardCount): number {
    if (!cardCount) return 0;
    const totalCards = Object.values(cardCount).reduce(
        (total, count) => total + (count || 0),
        0,
    );
    const acceptedCards = cardCount.accepted || 0;
    return totalCards > 0 ? (acceptedCards / totalCards) * 100 : 0;
}

const auditStatusColorMap: Record<string, string> = {
    'In Progress': 'bg-green-400',
    Pending: 'bg-gray-200',
    Locked: 'bg-gray-200',
};

const AuditStatusIcon: React.FC<{ status?: string }> = ({ status }) => {
    if (status === 'Locked') {
        return (
            <span className="inline-block mr-2">
                <LockClosedIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </span>
        );
    }

    return (
        <span
            aria-label={status}
            className={classNames(
                auditStatusColorMap[status || ''],
                'inline-block h-2 w-2 mr-2 flex-shrink-0 rounded-full',
            )}
        />
    );
};

export default function AuditSelect({ selectedAudit }: AuditSelectProps) {
    const { data, error, isLoading } = useGetAudits();
    const audits =
        data
            ?.sort(
                (a, b) =>
                    (b.auditCardCount?.awaiting_review || 0) -
                    (a.auditCardCount?.awaiting_review || 0),
            )
            ?.filter((a) => a.status !== 'Locked') || [];
    console.log('audits', audits);
    const [selected, setSelected] = useState<TransformedAuditBoard>();

    if (isLoading) return 'Loading...';
    if (error) return 'Error!';

    if (audits.length > 0 && !selected) {
        if (audits[0]) {
            setSelected(audits[0] as TransformedAuditBoard);
        }
    }

    if (selected && selectedAudit) {
        selectedAudit.value = selected;
    }

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <Listbox.Label className="sr-only">
                        Select Audit for Worksheet
                    </Listbox.Label>
                    <div className="relative ">
                        <div className="inline-flex divide-x divide-indigo-700 rounded-md shadow-sm">
                            <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm">
                                <AuditStatusIcon status={selected?.status} />
                                <p className="text-sm font-semibold min-w-24">
                                    {selected?.title || 'Please select an audit'}
                                </p>
                            </div>
                            <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50">
                                <span className="sr-only">Select Audit</span>
                                <ChevronDownIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                />
                            </Listbox.Button>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute left-0 z-10 mt-2 w-96 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto max-h-[60vh]">
                                {audits.map((audit) => (
                                    <Listbox.Option
                                        key={audit.id}
                                        className={({ active }) =>
                                            classNames(
                                                active
                                                    ? 'bg-indigo-600 text-white'
                                                    : 'text-gray-900',
                                                'cursor-default select-none p-4 text-sm',
                                            )
                                        }
                                        value={audit}
                                    >
                                        {({ selected: isSelected, active }) => (
                                            <div className="flex flex-col">
                                                <div className="flex justify-between">
                                                    <p
                                                        className={
                                                            isSelected
                                                                ? 'font-semibold'
                                                                : 'font-normal'
                                                        }
                                                    >
                                                        <AuditStatusIcon
                                                            status={audit?.status}
                                                        />
                                                        {audit.title}
                                                    </p>
                                                    {isSelected ? (
                                                        <span
                                                            className={
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-indigo-600'
                                                            }
                                                        >
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <div
                                                    className={classNames(
                                                        active
                                                            ? 'text-indigo-200'
                                                            : 'text-gray-500',
                                                        'mt-2',
                                                        'flex flex-col',
                                                    )}
                                                >
                                                    <div>
                                                        Status: {audit.status}
                                                        <span> | </span>
                                                        In Review:{' '}
                                                        {audit.auditCardCount
                                                            ?.awaiting_review || 0}
                                                        <span> | </span>
                                                        Accepted:{' '}
                                                        {audit.auditCardCount?.accepted ||
                                                            0}{' '}
                                                        (
                                                        {calculateAcceptedPercentage(
                                                            audit.auditCardCount,
                                                        ).toFixed(0)}
                                                        %)
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}
