import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';

import { selectedAudit } from '../state/audit';
import { ApiAudit, Board } from '../Types/Audit';
import { ApiCard, Card } from '../Types/Card';
import {
    transformBoardData,
    transformCard,
    transformCards,
    transformCardToApi,
} from './boardTransformer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchFromAPI<T>(endpoint: string, options?: RequestInit): Promise<T> {
    const response = await fetch(endpoint, options);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
}

export interface UpdateCardPayload {
    cardId: number;
    card: Partial<Card>;
}

async function updateCard(payload: UpdateCardPayload): Promise<ApiCard> {
    const transformedCard = transformCardToApi(payload.card);
    return fetchFromAPI<ApiCard>(`/api/cards/${payload.cardId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transformedCard),
    });
}

export interface UpdateCardsPayload {
    auditId: number;
    card: Partial<Card>;
}

async function updateCardsByRefId(payload: UpdateCardsPayload): Promise<ApiCard> {
    const apiCard = transformCardToApi(payload.card);

    return fetchFromAPI<ApiCard>(
        `/api/audits/${payload.auditId}/update_cards_by_ref_id`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...apiCard }),
        },
    );
}

export const useUpdateCardsByReview = () => {
    const queryClient = useQueryClient();

    return useMutation<ApiCard, Error, UpdateCardsPayload>({
        mutationFn: updateCardsByRefId,
        onSuccess: (card: ApiCard) => {
            const auditId = selectedAudit?.value?.id as number;
            flushSingleCardFromCache(queryClient, auditId, card);
        },
        onError: (e) => {
            console.error(e);
        },
    });
};

export const useUpdateCard = () => {
    const queryClient = useQueryClient();

    return useMutation<ApiCard, Error, UpdateCardPayload>({
        mutationFn: updateCard,
        onSuccess: (card: ApiCard) => {
            const auditId = selectedAudit?.value?.id as number;
            flushSingleCardFromCache(queryClient, auditId, card);
        },
        onError: (e) => {
            console.error(e);
        },
    });
};

export const useGetAudits = () => {
    return useQuery({
        queryKey: ['audits'],
        queryFn: () => fetchFromAPI<ApiAudit[]>(`/api/audits`),
        select: (data) => transformBoardData(data),
    });
};

export const useGetAuditCards = (auditId?: number) => {
    return useQuery({
        queryKey: ['audits', 'get', { auditId }],
        queryFn: () =>
            fetchFromAPI<{ board: Board; cards: ApiCard[] }>(`/api/audits/${auditId}`),
        enabled: !!auditId,
        select: (data) => transformCards(data.cards),
    });
};

export const useGetAuditBoard = (auditId?: number) => {
    return useQuery({
        queryKey: ['audits', 'get', { auditId }],
        queryFn: () =>
            fetchFromAPI<{ board: ApiAudit; cards: ApiCard[] }>(`/api/audits/${auditId}`),
        enabled: !!auditId,
        select: (data) => transformBoardData([data.board]),
    });
};

export const useGetCards = (auditId?: number) => {
    return useQuery({
        queryKey: ['audits', 'cards', { auditId }],
        queryFn: () => fetchFromAPI<ApiCard[]>(`/api/audits/${auditId}/cards`),
        select: (data) => transformCards(data),
        enabled: !!auditId,
    });
};

export const useGetCard = (auditId?: number, cardId?: number) => {
    const queryClient = useQueryClient();

    return useQuery({
        queryKey: ['audits', 'cards', 'get', { auditId, cardId }],
        queryFn: async () => {
            const cachedApiCards = queryClient.getQueryData<ApiCard[]>([
                'audits',
                'cards',
                { auditId },
            ]);

            if (cachedApiCards) {
                const card = cachedApiCards.find((card: ApiCard) => card.id === cardId);
                if (card) {
                    return card;
                }
            }

            const fetchedApiCards = await fetchFromAPI<ApiCard[]>(
                `/api/audits/${auditId}/cards`,
            );

            const card = fetchedApiCards.find((card: ApiCard) => card.id === cardId);

            if (!card) throw new Error('No card found');
            return card;
        },
        enabled: !!auditId && !!cardId,
        select: (data) => transformCard(data),
    });
};

const flushSingleCardFromCache = (
    queryClient: QueryClient,
    auditId: number,
    card: ApiCard,
) => {
    queryClient.setQueryData(
        ['audits', 'cards', { auditId }],
        (previousAuditData: ApiCard[] | undefined) => {
            if (previousAuditData) {
                let exists = false;
                const modifiedAuditData = previousAuditData.map((oldCard: ApiCard) => {
                    if (oldCard.id == card.id) {
                        exists = true;
                        return card;
                    }
                    return oldCard;
                });
                if (!exists) {
                    modifiedAuditData.push(card);
                }
                return modifiedAuditData;
            }
            return [];
        },
    );

    queryClient.setQueryData(
        ['audits', 'cards', 'get', { auditId, cardId: card.id }],
        () => {
            return card;
        },
    );

    queryClient.invalidateQueries({
        queryKey: ['audits', 'cards', 'get', { auditId, cardId: card.id }],
        refetchType: 'none',
    });

    queryClient.invalidateQueries({
        queryKey: ['audits', 'cards', { auditId }],
        refetchType: 'none',
    });
};
